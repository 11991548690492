import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ClerkProvider } from '@clerk/clerk-react';
import { Toaster } from 'react-hot-toast';
import * as serviceWorker from './serviceWorker';
import App from './App';
import config from './config';

import './assets/scss/style.scss';
import './themes/index.css';
import { store } from './store';
import { QueryClient, QueryClientProvider } from 'react-query';

if (process.env.ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  });
}

const queryClient = new QueryClient();

ReactDOM.render(
  <ClerkProvider
    publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY as string}
    afterSignInUrl="/dashboard"
    appearance={{
      elements: {
        footer: 'hidden',
        socialButtons: 'hidden',
        dividerRow: 'hidden'
      }
    }}
  >
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={config.basename}>
          <App />
          <Toaster position="bottom-right" />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </ClerkProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
