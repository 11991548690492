import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

const config = {
  borderRadius: 12,
  fontFamily: `'Roboto', sans-serif`
};

interface AppState {
  isOpen: string[];
  fontFamily: string;
  borderRadius: number;
  opened: boolean;
}

const initialState: AppState = {
  isOpen: [],
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    menuOpen: (state, action: PayloadAction<string>) => {
      state.isOpen = [action.payload];
    },
    setMenu: (state, action: PayloadAction<boolean>) => {
      state.opened = action.payload;
    },
    setFontFamily: (state, action: PayloadAction<string>) => {
      state.fontFamily = action.payload;
    },
    setBorderRadius: (state, action: PayloadAction<number>) => {
      state.borderRadius = action.payload;
    }
  }
});

export const { menuOpen, setMenu, setFontFamily, setBorderRadius } = appSlice.actions;

export const selectOpened = (state: RootState) => state.app.opened;
export const selectIsOpen = (state: RootState) => state.app.isOpen;
export const selectFontFamily = (state: RootState) => state.app.fontFamily;
export const selectBorderRadius = (state: RootState) => state.app.borderRadius;

export default appSlice.reducer;
