import { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import MainLayout from './../layout/MainLayout';
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';
import NavMotion from '../layout/NavMotion';

const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const ReportsPage = Loadable(lazy(() => import('../views/reports')));
const ReportsDetailPage = Loadable(lazy(() => import('../views/reports/detail')));
const ListingsPage = Loadable(lazy(() => import('../views/listings')));
const NewListingPage = Loadable(lazy(() => import('../views/listings/new')));
const EditListingPage = Loadable(lazy(() => import('../views/listings/edit')));
const BookingsPage = Loadable(lazy(() => import('../views/bookings')));
const VenuesPage = Loadable(lazy(() => import('../views/venues')));
const NewVenuePage = Loadable(lazy(() => import('../views/venues/new')));
const EditVenuePage = Loadable(lazy(() => import('../views/venues/edit')));
const CoachesPage = Loadable(lazy(() => import('../views/coaches')));
const CreateOrEditCoachPage = Loadable(lazy(() => import('../views/coaches/createOrUpdate')));
const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login')));

const MainRoutes = () => {
  let element = useRoutes([
    {
      path: '/login',
      element: (
        <MinimalLayout>
          <NavMotion>
            <AuthLogin />
          </NavMotion>
        </MinimalLayout>
      )
    },
    {
      path: '*',
      element: <MainLayout />,
      children: [
        { path: 'dashboard', element: <DashboardDefault /> },
        { path: 'reports', element: <ReportsPage /> },
        { path: 'reports/:id', element: <ReportsDetailPage /> },
        { path: 'listings', element: <ListingsPage /> },
        { path: 'listings/new', element: <NewListingPage /> },
        { path: 'listings/detail/:id', element: <EditListingPage /> },
        { path: 'bookings', element: <BookingsPage /> },
        { path: 'venues', element: <VenuesPage /> },
        { path: 'venues/new', element: <NewVenuePage /> },
        { path: 'venues/detail/:id', element: <EditVenuePage /> },
        { path: 'coaches', element: <CoachesPage /> },
        { path: 'coaches/new', element: <CreateOrEditCoachPage /> },
        { path: 'coaches/detail/:id', element: <CreateOrEditCoachPage /> }
      ]
    }
  ]);

  return <Suspense fallback={<div>Loading...</div>}>{element}</Suspense>;
};

export default MainRoutes;
