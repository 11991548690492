import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../lib/apiClient';
import { RootState } from '..';
import { ENDPOINTS } from '../../constants/api';
import { MonthlyEarning } from '../../types/dashboard';

interface DashboardState {
  monthlyEarnings: number;
  yearlyEarnings: MonthlyEarning[];
  currentYearEarnings: number;
  isLoading: boolean;
  hasError: boolean;
}

const initialState: DashboardState = {
  monthlyEarnings: 0,
  yearlyEarnings: [],
  currentYearEarnings: 0,
  isLoading: false,
  hasError: false
};

export const fetchMonthlyEarnings = createAsyncThunk('dashboard/fetchMonthlyEarnings', async () => {
  const response = await apiClient.get(`${ENDPOINTS.DASHBOARD}/monthly-earnings`);
  return { data: response.data };
});

export const fetchYearlyEarnings = createAsyncThunk('dashboard/fetchYearlyEarnings', async () => {
  const response = await apiClient.get(`${ENDPOINTS.DASHBOARD}/yearly-earnings`);
  return { data: response.data };
});

export const fetchCurrentYearEarnings = createAsyncThunk('dashboard/fetchCurrentYearEarnings', async () => {
  const response = await apiClient.get(`${ENDPOINTS.DASHBOARD}/current-year-earnings`);
  return { data: response.data };
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMonthlyEarnings.pending, (state, action) => {
        state.hasError = false;
        state.isLoading = true;
        state.monthlyEarnings = 0;
      })
      .addCase(fetchMonthlyEarnings.fulfilled, (state, action) => {
        state.hasError = false;
        state.isLoading = false;
        state.monthlyEarnings = action.payload.data.data.monthlyEarnings;
      })
      .addCase(fetchMonthlyEarnings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
        state.monthlyEarnings = 0;
      })
      .addCase(fetchYearlyEarnings.pending, (state, action) => {
        state.hasError = false;
        state.isLoading = true;
        state.yearlyEarnings = [];
      })
      .addCase(fetchYearlyEarnings.fulfilled, (state, action) => {
        state.hasError = false;
        state.isLoading = false;
        state.yearlyEarnings = action.payload.data.data.yearlyEarnings;
      })
      .addCase(fetchYearlyEarnings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
        state.yearlyEarnings = [];
      })
      .addCase(fetchCurrentYearEarnings.pending, (state, action) => {
        state.hasError = false;
        state.isLoading = true;
        state.currentYearEarnings = 0;
      })
      .addCase(fetchCurrentYearEarnings.fulfilled, (state, action) => {
        state.hasError = false;
        state.isLoading = false;
        state.currentYearEarnings = action.payload.data.data.currentYearEarnings;
      })
      .addCase(fetchCurrentYearEarnings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
        state.currentYearEarnings = 0;
      });
  }
});

export const selectMonthlyEarnings = (state: RootState) => state.dashboard.monthlyEarnings;
export const selectYearlyEarnings = (state: RootState) => state.dashboard.yearlyEarnings;
export const selectCurrentYearEarnings = (state: RootState) => state.dashboard.currentYearEarnings;

export default dashboardSlice.reducer;
