import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiClient from '../../lib/apiClient';
import { RootState } from '..';
import { ENDPOINTS } from '../../constants/api';
import { Report } from '../../types/reports';

interface ReportsState {
  monthlyReports: Report[];
  isLoading: boolean;
  hasError: boolean;
}

const initialState: ReportsState = {
  monthlyReports: [],
  isLoading: false,
  hasError: false
};

export const fetchMonthlyReports = createAsyncThunk('reports/fetchMonthlyReports', async () => {
  const response = await apiClient.get(`${ENDPOINTS.DASHBOARD}/monthly-reports`);
  return { data: response.data };
});

export const fetchSingleReport = async (reportId: string, venueId?: string, coachId?: string) => {
  const response = await apiClient.get(`${ENDPOINTS.DASHBOARD}/monthly-reports/${reportId}`, {
    params: {
      venueId,
      coachId
    }
  });
  return response.data;
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMonthlyReports.pending, (state, action) => {
        state.hasError = false;
        state.isLoading = true;
        state.monthlyReports = [];
      })
      .addCase(fetchMonthlyReports.fulfilled, (state, action) => {
        state.hasError = false;
        state.isLoading = false;
        state.monthlyReports = action.payload.data.data.monthlyReports;
      })
      .addCase(fetchMonthlyReports.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
        state.monthlyReports = [];
      });
  }
});

export const selectMonthlyReports = (state: RootState) => state.reports.monthlyReports;
export const selectMonthlyReportsLoading = (state: RootState) => state.reports.isLoading;

export default reportsSlice.reducer;
