import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../lib/apiClient';
import { RootState } from '..';
import { ENDPOINTS } from '../../constants/api';
import { isEmptyObject } from '../../utils/isEmptyObject';
import axios from 'axios';

type ImageResource = {
  asset_id: string;
  public_id: string;
  createdAt: string;
  folder: 'jwtennis/venues' | 'jwtennis/listing';
};

type ImageResponse = {
  data: {
    data: ImageResource[];
    count: number;
  };
};

type ImageUploadResponse = {
  data: ImageResource;
};

interface BookingState {
  venueImages: ImageResource[];
  listingImages: ImageResource[];
  venueImageCount: number;
  listingImageCount: number;
  currentVenuePage: number;
  currentListingPage: number;
  isLoading: boolean;
  hasError: boolean;
}

interface FetchFilters {
  page?: number;
  folder?: 'jwtennis/venues' | 'jwtennis/listing';
}

const initialState: BookingState = {
  venueImages: [],
  listingImages: [],
  isLoading: false,
  hasError: false,
  venueImageCount: 0,
  listingImageCount: 0,
  currentVenuePage: 1,
  currentListingPage: 1
};

const defaultFilters = {
  page: 1
};

export const fetchAllImages = createAsyncThunk('images/fetchAll', async (filters: FetchFilters = {}) => {
  // @ts-ignore
  const response = await apiClient.get<ImageResponse>(ENDPOINTS.IMAGES, { params: !isEmptyObject(filters) ? filters : defaultFilters });
  return { data: response.data.data, filters };
});

export const uploadImage = createAsyncThunk('images/uploadImage', async (formData: FormData, { dispatch }) => {
  try {
    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );

    if (response.data && response.data.secure_url) {
      const { asset_id, public_id, folder } = response.data;
      const res = await apiClient.post<ImageUploadResponse>(ENDPOINTS.IMAGES, {
        folder,
        asset_id,
        public_id
      });

      return res.data.data;
    }
  } catch (error) {
    console.error('Error uploading image: ', error);
    throw error;
  }
});

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllImages.pending, (state, action) => {
        state.hasError = false;
        state.isLoading = true;
      })
      .addCase(fetchAllImages.fulfilled, (state, action) => {
        const folder = action.meta.arg?.folder;

        if (folder === 'jwtennis/listing') {
          state.listingImageCount = action.payload.data.count;
          state.listingImages = [...state.listingImages, ...action.payload.data.data];
          state.currentListingPage = state.currentListingPage + 1;
        } else {
          state.venueImageCount = action.payload.data.count;
          state.venueImages = [...state.venueImages, ...action.payload.data.data];
          state.currentVenuePage = state.currentVenuePage + 1;
        }

        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(fetchAllImages.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(uploadImage.pending, (state, action) => {})
      .addCase(uploadImage.fulfilled, (state, action) => {
        if (!action.payload) return;
        const folder = action.payload?.folder;

        if (folder === 'jwtennis/listing') {
          state.listingImages = [action.payload, ...state.listingImages];
        } else {
          state.venueImages = [action.payload, ...state.venueImages];
        }
      })
      .addCase(uploadImage.rejected, (state, action) => {});
  }
});

export const selectVenueImages = (state: RootState) => state.images.venueImages;
export const selectListingImages = (state: RootState) => state.images.listingImages;

export const selectListingImageCount = (state: RootState) => state.images.listingImageCount;
export const selectVenueImageCount = (state: RootState) => state.images.venueImageCount;

export const selectCurrentVenuePage = (state: RootState) => state.images.currentVenuePage;
export const selectCurrentListingPage = (state: RootState) => state.images.currentListingPage;

export default imagesSlice.reducer;
