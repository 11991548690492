import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import appSlice from './slices/app.slice';
import bookingsSlice from './slices/bookings.slice';
import dashboardSlice from './slices/dashboard.slice';
import reportsSlice from './slices/reports.slice';
import imagesSlice from './slices/images.slice';

export const store = configureStore({
  reducer: {
    app: appSlice,
    bookings: bookingsSlice,
    dashboard: dashboardSlice,
    reports: reportsSlice,
    images: imagesSlice
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
