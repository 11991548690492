import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { SignedIn, useAuth, useUser } from '@clerk/clerk-react';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import { AppBar, CssBaseline, Toolbar, useMediaQuery } from '@material-ui/core';

// third-party
import clsx from 'clsx';

// project imports
import Breadcrumbs from './../../components/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from './../../menu-items';
import { DRAWER_WIDTH } from '../../constants/ui';

import { selectOpened, setMenu } from './../../store/slices/app.slice';

// assets
import { IconChevronRight } from '@tabler/icons';
import { useAppDispatch, useAppSelector } from '../../hooks/useStore';

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: theme.palette.background.default
  },
  appBarWidth: {
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.background.default
  },
  content: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(DRAWER_WIDTH - 20),
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  }
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }) => {
  const { userId, isLoaded, signOut } = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();

  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  // Handle left drawer
  const leftDrawerOpened = useAppSelector(selectOpened);
  const dispatch = useAppDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch(setMenu(!leftDrawerOpened));
  };

  useEffect(() => {
    if (!userId && isLoaded) {
      navigate('/login');
    }
  }, [userId, isLoaded, navigate]);

  useEffect(() => {
    dispatch(setMenu(!matchDownMd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (isLoaded && user?.publicMetadata?.role !== 'ADMIN') {
    signOut();
    navigate('/login');
  }

  return (
    <SignedIn>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
        >
          <Toolbar>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>

        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

        <main
          className={clsx([
            classes.content,
            {
              [classes.contentShift]: leftDrawerOpened
            }
          ])}
        >
          <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
          <Outlet />
        </main>
      </div>
    </SignedIn>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
