import { Suspense, ComponentType } from 'react';

import Loader from './Loader';

type PropsWithChildren<P> = P & { children?: React.ReactNode };

const Loadable =
  <P,>(Component: ComponentType<P>) =>
  (props: PropsWithChildren<P>) =>
    (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );

export default Loadable;
