import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

import theme from './themes';
import MainRoutes from './routes/MainRoutes';

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(undefined)}>
        <CssBaseline />
        <MainRoutes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
