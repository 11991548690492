import { SignedOut } from '@clerk/clerk-react';
import PropTypes from 'prop-types';

//-----------------------|| MINIMAL LAYOUT ||-----------------------//

const MinimalLayout = (props) => {
  return <SignedOut>{props.children}</SignedOut>;
};

MinimalLayout.propTypes = {
  children: PropTypes.node
};

export default MinimalLayout;
