import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../lib/apiClient';
import { Booking } from '../../types';
import { RootState } from '..';
import { ENDPOINTS } from '../../constants/api';
import { isEmptyObject } from '../../utils/isEmptyObject';

interface BookingState {
  bookings: {
    [key: string]: Booking[];
  };
  filter: string;
  isLoading: boolean;
  hasError: boolean;
  count: number;
  currentPage: number;
}

interface FetchFilters {
  page?: number;
  venueId?: string;
  fromDate?: string;
  toDate?: string;
}

const initialState: BookingState = {
  bookings: {},
  filter: '',
  isLoading: false,
  hasError: false,
  count: 0,
  currentPage: 1
};

const defaultFilters = {
  page: 1
};

export const fetchAllBookings = createAsyncThunk('bookings/fetchAll', async (filters: FetchFilters = {}) => {
  // @ts-ignore
  const response = await apiClient.get(ENDPOINTS.BOOKINGS, { params: !isEmptyObject(filters) ? filters : defaultFilters });
  return { data: response.data, filters };
});

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBookings.pending, (state, action) => {
        state.hasError = false;
        state.isLoading = true;
        state.filter = '';
      })
      .addCase(fetchAllBookings.fulfilled, (state, action) => {
        const key = JSON.stringify(action.payload.filters);

        state.hasError = false;
        state.isLoading = false;
        state.filter = key;
        state.bookings[key] = action.payload.data.data.data;
        state.count = action.payload.data.data.count;
        state.currentPage = action.payload.filters.page || state.currentPage;
      })
      .addCase(fetchAllBookings.rejected, (state) => {
        state.hasError = true;
        state.isLoading = false;
        state.filter = '';
      });
  }
});

export const selectBookings = (state: RootState, filters: any = {}) => {
  let key;
  if (!isEmptyObject(filters)) {
    key = JSON.stringify(filters);
  } else {
    key = state.bookings.filter;
  }

  return state.bookings.bookings[key];
};

export const selectFilter = (state: RootState) => state.bookings.filter;
export const selectCount = (state: RootState) => state.bookings.count;
export const selectCurrentPage = (state: RootState) => state.bookings.currentPage;

export default bookingsSlice.reducer;
