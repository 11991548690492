// assets
import { IconDashboard, IconDeviceAnalytics, IconBookmarks, IconClipboardList, IconMap, IconUsers, IconFileAnalytics } from '@tabler/icons';

// constant
const icons = {
  IconDashboard: IconDashboard,
  IconDeviceAnalytics,
  IconBookmarks,
  IconClipboardList,
  IconMap,
  IconUsers,
  IconFileAnalytics
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons['IconDashboard'],
      breadcrumbs: false
    },
    {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      url: '/reports',
      icon: icons['IconFileAnalytics'],
      breadcrumbs: false
    },
    {
      id: 'listings',
      title: 'Classes',
      type: 'item',
      url: '/listings',
      icon: icons['IconBookmarks'],
      breadcrumbs: false
    },
    {
      id: 'bookings',
      title: 'Bookings',
      type: 'item',
      url: '/bookings',
      icon: icons['IconClipboardList'],
      breadcrumbs: false
    },
    {
      id: 'venues',
      title: 'Venues',
      type: 'item',
      url: '/venues',
      icon: icons['IconMap'],
      breadcrumbs: false
    },
    {
      id: 'coaches',
      title: 'Coaches',
      type: 'item',
      url: '/coaches',
      icon: icons['IconUsers'],
      breadcrumbs: false
    }
  ]
};
